<template>

<!-- 机构端 校园筛查 报表中心 学校报表 -->
  <div class="page">
    <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="我创建的筛查计划" name="0"></el-tab-pane>
        <el-tab-pane label="学校上报的筛查计划" name="1"></el-tab-pane>
      </el-tabs>
    <div class="header-container">
      <div class="header-section">
        <div class="button-section">
          <el-button type="primary" size="mini"  @click="goWord" round >导出word</el-button>
          <el-button type="primary" size="mini"  @click="gopPdf" round >导出pdf</el-button>
         </div>
      </div>
    </div>

<!--    <title-text :exp-button="true" :screening-id="screeningListId"></title-text>-->
    <el-alert
      title="学生报表按照学校、年级、班级展示汇总数据，可以切换筛查计划，以图表形式查看、导出。"
      style="border: 1px solid #FA6401;margin-top: 20px;background: rgba(245, 98, 0, 0.05);color: #999999;"
      type="warning"
    ></el-alert>
    <div class="select" style="margin-top: 20px;">
      <div class="header-input" v-if="activeName == 1">
        <el-select v-model="selectSchool" placeholder="请选择学校" size="mini" @change="getScreeningSelectS">
          <el-option
            v-for="item in schoolList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-select v-model="screeningListId" size="mini" filterable placeholder="请选择筛查计划">
        <el-option
          v-for="(item,index) in screeningOptions"
          :key="item.id"
          :label="item.screeningName"
          :value="item.id"
          @click.native="changeScreening(index)"
        ></el-option>
      </el-select>
      <el-select  v-if="activeName == 0" v-model="schoolId" size="mini" placeholder="请选择学校" @change="getListData">
        <el-option
          v-for="item in schoolOptions"
          :key="item.schoolId"
          :label="item.schoolName"
          :value="item.schoolId"
        ></el-option>
      </el-select>
      <!-- <el-select v-model="gradeId" size="mini" clearable :disabled="true" placeholder="请选择年级" @change="getClassSelect">
        <el-option
          v-for="item in gradeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-select v-model="classId" size="mini" :disabled="true" placeholder="请选择班级">
        <el-option
          v-for="item in classOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select> -->
    </div>
    <div class="report"  v-if="!inResize && screeningListId" >
      <div ref="pdfs" id="pdfs" style="display:flex;overflow: hidden;">
        <div class="page-content"  id="htmlPdf" ref="htmlPdf" style="position: relative;padding: 0 40px;width: 800px;margin-top: 0; font-size: 24px;" :style="pdfStyle">
        <div class="page-item" style="padding-top: 40px">
          <header>
            <h1>{{tableData.schoolName}}</h1>
            <h2>学校视力情况分析一览</h2>
          </header>
          <div class="center center-tj">
            <div class="header-title">{{tableData.schoolName}}</div>
            <div class="time">筛查时间：<span class="classData">{{tableData.screeningDate}}</span></div>
            <div class="line"></div>
            <div class="all">
              <h3>总览</h3>
              <div class="list">
                <div class="item">
                  <span class="classData">{{tableData.count}}人</span>
                  <span>计划筛查学生</span>
                </div>
                <div class="item">
                  <span class="classData">{{tableData.haveCount}}人</span>
                  <span>实际完成人数</span>
                </div>
                <div class="item">
                  <span class="classData">{{tableData.completeThan}}</span>
                  <span>筛查完成率</span>
                </div>
                <div class="item">
                  <span class="classData">{{tableData.leakageThan}}</span>
                  <span>漏项学生率</span>
                </div>
                <div class="item">
                  <span class="classData">{{tableData.badVisionThan}}</span>
                  <span>视力异常率</span>
                </div>
                <div class="item">
                  <span class="classData">{{tableData.lightVisionThan}}</span>
                  <span>轻度视力低下率</span>
                </div>
                <div class="item">
                  <span class="classData">{{tableData.middleVisionThan}}</span>
                  <span>中度视力低下率</span>
                </div>
                <div class="item">
                  <span class="classData">{{tableData.heavyVisionThan}}</span>
                  <span>重度视力低下率</span>
                </div>
                <div class="item">
                  <span class="classData">{{tableData.badAmetropiaThan}}</span>
                  <span>屈光异常率</span>
                </div>
                <div class="item">
                  <span class="classData">{{tableData.lightAmetropiaThan}}</span>
                  <span>轻度屈光不正率</span>
                </div>
                <div class="item">
                  <span class="classData">{{tableData.middleAmetropiaThan}}</span>
                  <span>中度屈光不正率</span>
                </div>
                <div class="item">
                  <span class="classData">{{tableData.heavyAmetropiaThan}}</span>
                  <span>重度屈光不正率</span>
                </div>
              </div>
              <div class="tips1">
                <div>视力不良标准：根据《标准对数视力表》（GB11533—2011）检查远视力，6岁以上儿童青少年裸眼视力低于5.0。</div>
                <div>轻度视力不良：裸眼视力=4.9</div>
                <div>中度视力不良：4.6≤裸眼视力≤4.8</div>
                <div>重度视力不良：裸眼视力≤4.5</div>
                <div>屈光检查：<span class="red">非睫状肌麻痹状态下电脑验光。</span></div>
                <div><span class="red">屈光状态异常标准：通过非睫状肌麻痹下电脑验光，等效球镜（SE）＜﹣0.50D。</span></div>
                <div><span class="red">注：等效球镜SE=球镜DS+柱镜DC/2</span></div>
              </div>
            </div>
          </div>
        </div>

        <div class="page-item">
          <div class="center">
            <div class="basic">
              <div class="title">筛查基本情况</div>
              <div class="summary"><div class="shu"></div><div class="shu2"></div><div>项目概述</div></div>
              <div>
                <h3>根据教育部、国家卫生健康委等八部门印发的《综合防控儿童青少年近视实施方案》一文要求，为全面了解{{tableData.schoolName}}学校学生视力不良情况，以便制定合理有效的视力不良干预防治措施提供科学依据，本校<span class="classData">{{tableData.screeningDate}}</span>开始对在校学生进行了视力(按筛查计划类型)健康检查工作。</h3>
                <h3>本次监测建立了数据实时的预警机制、家校联动的预防机制、全面精确的检查机制和个性的防控机制，构建了”互联网 +数据采集“、“互联网+ 视力预警监测”与”互联网+统计分析“近视防控平台。最终采用”学生视力监测平台”进行了统计分析,形成监测报告。</h3>
                <h3>本报告中的所有数据均来源于现场采集,报告只对数据结果进行客观呈现，供参考使用，现将学生视力检查结果报告如下:</h3>
              </div>
              <div class="summary"><div class="shu"></div><div class="shu2"></div><div>检测对象</div></div>
              <div class="box">
                <span>检测学校</span>
                <span class="classData">{{tableData.schoolName}}</span>
              </div>
              <div class="result">
                <div class="left">
                  <div class="title">
                    检测年级
                  </div>
                  <div class="list">
                    <div class="item" :key="index" v-for="( item, index ) in gradeMapList"><span class="classData">{{item.gradeName}}</span></div>
                  </div>
                </div>
                <div class="line2"></div>
                <div class="right">
                  <div class="title">
                    筛查总数
                  </div>
                  <div class="list">
                    <div class="item">
                      <span>学生总数</span>
                      <span class="classData">{{tableData.haveCount}}人</span>
                    </div>
                    <div class="item">
                      <span>男生  </span>
                      <span class="classData">{{tableData.manCount}}人</span>
                    </div>
                    <div class="item">
                      <span>女生</span>
                      <span class="classData">{{tableData.girlCount}}人</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tableBox page-item">
          <div class="title">
            {{tableData.schoolName}} 学校视力情况分析表
          </div>
          <table class="table">
            <tr>
              <td>学校名称</td>
              <td><span class="classData">{{tableData.schoolName}}</span></td>
              <td>总人数</td>
              <td><span class="classData">{{tableData.count}}</span></td>
              <td>实测人数</td>
              <td><span class="classData">{{tableData.haveCount}}</span></td>
            </tr>
            <tr>
              <td colspan="6">视力构成情况</td>
            </tr>
            <tr>
              <td>单位</td>
              <td>统计选项</td>
              <td colspan="4">图表分析</td>
            </tr>
            <tr>
              <td style="display:table-cell; vertical-align:middle">双眼</td>
              <td style="display:table-cell; vertical-align:middle">视力不良率</td>
              <td colspan="4" style="display:table-cell; vertical-align:middle" align="center">
                <div class="chartBox" ref="chartBox1" style="width: 100%;height: 15em">
                  <div id="myChart1" style="width: 100%;height: 100%"></div>
                </div>
                <div class="chartBox" ref="chartBox2" style="width: 100%;height: 15em">
                  <div id="myChart2" style="width: 100%;height: 100%"></div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="1" style="display:table-cell; vertical-align:middle">说明</td>
              <td colspan="5" align="left">
                <div style="width:600px;line-height: 2.4">
                  <div>{{tableData.schoolName}}应检人数<span class="classData">{{tableData.count}}</span>人，实检人数<span class="classData">{{tableData.haveCount}}</span>人。其中双眼视力正常（５.０以上）人数为<span class="classData">{{tableData.goodVisionNum}}人</span>人，占<span class="classData">{{tableData.goodVisionThan}}人</span>，视力不良（５.０以下）人数为<span class="classData">{{tableData.badVisionNum}}人</span>人，占<span class="classData">{{tableData.badVisionThan}}人</span></div>
                  <ul class="desc">
                    <li>视力不良中：</li>
                    <li>轻度视力不良人数为<span class="classData">{{tableData.lightVisionNum}}</span>人，占<span class="classData">{{tableData.lightVisionThan}}人</span></li>
                    <li>需矫视力不良人数为<span class="classData">{{tableData.middleVisionNum}}</span>人，占<span class="classData">{{tableData.middleVisionThan}}人</span></li>
                    <li>需矫严重不良人数为<span class="classData">{{tableData.heavyVisionNum}}</span>人，占<span class="classData">{{tableData.heavyVisionThan}}人</span></li>
                  </ul>
                </div>

              </td>
            </tr>
          </table>
        </div>

        <div class="tableBox page-item">
          <div class="title">
            {{tableData.schoolName}} 全校视力左右眼分析表
          </div>
          <table class="table">
            <tr>
              <td colspan="2" style="display:table-cell; vertical-align:middle;white-space: nowrap;">视力分布图</td>
              <td colspan="4" style="display:table-cell; vertical-align:middle" align="center">
                <div class="chartBox" ref="chartBox3" style="width: 100%;height: 15em; padding-bottom: 3rem">
                  <div id="myChart3" style="width: 100%;height: 100%"></div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="1" style="display:table-cell; vertical-align:middle">说明</td>
              <td colspan="5" align="left">
                <div style="width:600px;line-height: 2.4">
                  <div>{{tableData.schoolName}}应检人数<span class="classData">{{tableData.count}}</span>人，实检人数<span class="classData">{{tableData.haveCount}}</span>人，实检眼数<span class="classData">{{tableData.haveCount*2}}</span>只。</div>
                  <ul class="desc">
                    <li>视力为5.0以上（视力优良）眼数有<span class="classData">{{tableData.normalOnly}}</span>只，占<span class="classData">{{tableData.normalOnlyThan}}</span>。</li>
                    <li>视力为4.9（轻度视力不良）眼数有<span class="classData">{{tableData.lightOnly}}</span>只，占<span class="classData">{{tableData.lightOnlyThan}}</span>。</li>
                    <li>视力为4.6-4.8（需矫视力不良）眼数有<span class="classData">{{tableData.middleOnly}}</span>只，占<span class="classData">{{tableData.middleOnlyThan}}</span>。</li>
                    <li>视力为4.6以下（需矫严重不良）眼数有<span class="classData">{{tableData.heavyOnly}}</span>只，占<span class="classData">{{tableData.heavyOnlyThan}}</span>。</li>
                  </ul>
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div class="tableBox page-item">
          <div class="title">
            全校屈光状态分析表
          </div>
          <table class="table">
            <tr>
              <td>学校名称</td>
              <td><span class="classData">{{tableData.schoolName}}</span></td>
              <td>总人数</td>
              <td><span class="classData">{{tableData.count}}</span></td>
              <td>实测人数</td>
              <td><span class="classData">{{tableData.haveCount}}</span></td>
            </tr>
            <tr>
              <td colspan="6">屈光状态</td>
            </tr>
            <tr>
              <td>单位</td>
              <td>统计选项</td>
              <td colspan="4">图表分析</td>
            </tr>
            <tr>
              <td style="display:table-cell; vertical-align:middle">双眼</td>
              <td style="display:table-cell; vertical-align:middle">屈光异常率</td>
              <td colspan="4" style="display:table-cell; vertical-align:middle" align="center">
                <div class="chartBox" ref="chartBox4" style="width: 100%;height: 15em">
                  <div id="schoolReportMyChart4" style="width: 100%;height: 100%"></div>
                </div>
                <div class="chartBox" ref="chartBox5" style="width: 100%;height: 15em">
                  <div id="myChart5" style="width: 100%;height: 100%"></div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="1" style="display:table-cell; vertical-align:middle">说明</td>
              <td colspan="5" align="left">
                <div style="width:600px;line-height: 2.4; font-size:12px;">
                  <div><span class="classData">{{tableData.schoolName}}</span>应检人数<span class="classData">{{tableData.count}}</span>人，实检人数<span class="classData">{{tableData.haveCount}}</span>人。其中双眼屈光正常（等效球镜SE＞-0.5以上）人数为<span class="classData">{{tableData.goodAmetropiaNum}}</span>人，占<span class="classData">{{tableData.goodAmetropiaThan}}</span>，屈光不正（等效球镜SE≤-0.5）人数为<span class="classData">{{tableData.badAmetropiaNum}}</span>人，占<span class="classData">{{tableData.badAmetropiaThan}}</span>。</div>
                  <ul class="desc">
                    <li>屈光异常中：</li>
                    <li>轻度屈光不正（-3.0＜等效球镜SE≤-0.5）人数为<span class="classData">{{tableData.lightAmetropiaNum}}</span>人，占<span class="classData">{{tableData.lightAmetropiaThan}}</span></li>
                    <li>中度屈光不正（-6.0≤等效球镜SE≤-3.0）人数为<span class="classData">{{tableData.middleAmetropiaNum}}</span>人，占<span class="classData">{{tableData.middleAmetropiaThan}}</span></li>
                    <li>重度屈光不正（等效球镜SE＜-6.0）人数为<span class="classData">{{tableData.heavyAmetropiaNum}}</span>人，占<span class="classData">{{tableData.heavyAmetropiaThan}}</span></li>
                  </ul>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="page-item">
          <div class="uneven">
            <div class="title">全校筛查性近视及屈光参差</div>
            <div class="summary">我校筛查性近视率为<span class="classData">{{tableData.badVisionThan}}</span>，屈光参差率为<span class="classData">{{tableData.badAmetropiaThan}}</span> 。</div>
            <div class="details">筛查性近视：应用远视力检查、非睫状肌麻痹状态下电脑验光（俗称电脑验光）或串镜检查等快速、简便的方法，将儿童青少年中可能患有近视者筛选出来。当6岁以上儿童青少年裸眼远视力＜5.0 时，通过非睫状肌麻痹下电脑验光，等效球镜（SE）＜﹣0.50D 判定为筛查性近视。</div>
            <div class="unevenTips">（屈光参差：全国儿童弱视斜视防治学组（1985）提出统一试行诊断标准“两眼屈光度相差球镜≥1.50D，柱镜≥1D者为病理性屈光参差”）</div>
          </div>
        </div>
        <div class="page-item">
          <div class="review">
            <div class="title"> 全校全年级需要复查统计表</div>
            <table>
              <tr>
                <td>班级</td>
                <td>需要复查人数</td>
                <td>年级总人数</td>
                <td>复查率</td>
              </tr>
              <tr v-for="(item,index) in gradeMapList" :key="index">
                <td>{{ item.gradeName }}</td>
                <td>{{ item.reviewNums }}</td>
                <td>{{ item.gradeCount }}</td>
                <td>{{ item.reviewThan }}</td>
              </tr>
              <tr>
                <td>统计</td>
                <td>{{ tableData.allReviewNums }}</td>
                <td>{{ tableData.allGradeCount }}</td>
                <td>{{ tableData.allAeviewThan }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="page-item">
          <div class="fcsm">
            <div class="title t2">复查说明：</div>
            <div class="text">需要复查的群体为在本次筛查中存在视力异常或屈光不正的学生，可能或已经出现了近视，需进一步到眼科医院进行复查出才能确认结果，建议安排各班主任及时通知。</div>
          </div>
        </div>

        <template class="classitem" v-for="(item,index) in gradeClass">
          <div :key="index">
              <div class="mainTitle page-item" v-if="index==0">
              <header>
                <h1>{{tableData.schoolName}}</h1>
                <h2 style="font-weight: 700">各年级视力异常及屈光不正统计表</h2>
              </header>
            </div>
            <div class="page-item">
              <div class="classTitle">{{item.gradeName}} 年级段视力情况分析表</div>
              <table class="table">
                <tr>
                  <td>年级</td>
                  <td><span class="classData">{{item.gradeName}}</span></td>
                  <td>总人数</td>
                  <td><span class="classData">{{item.gradeCount}}</span></td>
                  <td>实测人数</td>
                  <td><span class="classData">{{item.gradehaveCount}}</span></td>
                </tr>
                <tr>
                  <td style="display:table-cell; vertical-align:middle">双眼</td>
                  <td style="display:table-cell; vertical-align:middle">视力不良率</td>
                  <td colspan="4" style="display:table-cell; vertical-align:middle" align="center">
                    <div class="chartBox" :ref="'gradeChartBox'+index" style="width: 100%;height: 17em">
                      <div :id="'gradeChart'+index" style="width: 100%;height: 100%"></div>
                    </div>
                    <div class="chartBox" :ref="'gradeChartBox'+index" style="width: 100%;height: 17em">
                      <div :id="'gradeChartFb'+index" style="width: 100%;height: 100%"></div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="1" style="display:table-cell; vertical-align:middle">说明</td>
                  <td colspan="5" align="left">
                    <div style="width:600px;line-height: 2.4">
                      <div><span class="classData">{{item.gradeName}}</span>应检人数<span class="classData">{{item.gradeName}}</span>人，实检人数<span class="classData">{{item.gradeName}}</span>人。其中全年级视力正常（５.０以上）人数为<span class="classData">{{item.goodVisionNum}}</span>人，占<span class="classData">{{item.goodVisionThan}}</span>，视力不良（５.０以下）人数为<span class="classData">{{item.badVisionNum}}</span>人，占<span class="classData">{{item.badVisionThan}}</span>。</div>
                      <ul class="desc">
                        <li>视力不良中：</li>
                        <li>轻度视力不良人数为<span class="classData">{{item.lightVisionNum}}</span>人，占<span class="classData">{{item.lightVisionThan}}</span></li>
                        <li>需矫视力不良人数为<span class="classData">{{item.middleVisionNum}}</span>人，占<span class="classData">{{item.middleVisionThan}}</span></li>
                        <li>需矫严重不良人数为<span class="classData">{{item.goodVisionNum}}</span>人，占<span class="classData">{{item.goodVisionThan}}</span></li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div class="Classes  page-item">
              <div class="classTitle">全校{{item.gradeName}}年段各班级视力异常情况分析表</div>
              <div class="title">{{item.gradeName}}各班级视力情况统计表</div>
              <table>
                <tr>
                  <td>班级</td>
                  <td>班级总人数</td>
                  <td>视力不良人数</td>
                  <td>屈光异常人数</td>
                  <td>视力不良率</td>
                  <td>屈光异常率</td>
                </tr>
                <tr v-for="(classItem,index) in item.classList" :key="index">
                  <td><span class="classData">{{classItem.className}}</span></td>
                  <td><span class="classData">{{classItem.classCont}}</span></td>
                  <td><span class="classData">{{classItem.classvision}}</span></td>
                  <td><span class="classData">{{classItem.classAmetropia}}</span></td>
                  <td><span class="classData">{{classItem.classvisionThan}}</span></td>
                  <td><span class="classData">{{classItem.classAmetropiaThan}}</span></td>
                </tr>
              </table>
            </div>
            <div class="Classes  page-item">
              <div class="title title2">{{item.gradeName}}各班级需要复查人数统计表</div>
              <table>
                <tr>
                  <td>班级</td>
                  <td>需要复查人数</td>
                  <td>班级总人数</td>
                  <td>复查率</td>
                </tr>
                <tr v-for="(classItem,index) in item.classList" :key="index">
                  <td><span class="classData">{{classItem.className}}</span></td>
                  <td><span class="classData">{{classItem.classReview}}</span></td>
                  <td><span class="classData">{{classItem.classCont}}</span></td>
                  <td><span class="classData">{{classItem.classReviewThan}}</span></td>
                </tr>
              </table>
            </div>
          </div>
        </template>

        <div class="footer-section page-item" :style="detailSHow?'min-height:1138px':''">
          <div class="suggestion">报告结论与建议：</div>
          <div class="suggestion-title">
            <h3>一、报告结论：</h3>
            <p>本次筛查发现，我校视力不良率为<span class="classData">{{ tableData.badVisionThan }}</span>，屈光异常率为<span class="classData">{{ tableData.badAmetropiaThan }}</span>，筛查性近视率为<span class="classData">{{ tableData.badVisionThan }}</span>，需要复查人数为<span class="classData">{{ tableData.allReviewNums }}</span>人。</p>
            <p>全校轻度视力不良的群体很大比例为假性近视，及早干预可避免发展成为真性近视，共计<span class="classData">{{ tableData.lightVisionNum }}</span>人，占比<span class="classData">{{ tableData.lightVisionThan }}</span>，</p>
          </div>
          <div class="suggestion-title">
            <h3>二、建议：</h3>
            <p>1.根据国家卫健委印发的《儿童青少年近视防控适宜技术指南》，通过近视筛查以及监测等工作，对儿童青少年进行分级管理，科学转诊。</p>
            <p>2.视力正常、但远视储备不足、存在近视高危因素的学生，学校应督促其改善不良用眼习惯，增加户外活动时间，预防近视发生。</p>
            <p>3.裸眼视力下降、屈光检查异常、或戴镜视力不良的学生，学校应督促其尽快到医疗机构接受医学验光等进一步检查，明确诊断并及时采取措施矫治。</p>
            <p>4.戴镜视力正常的学生，学校应进行宣教，督促其每6～12月到医疗机构进行裸眼、戴镜视力等相关检查，观察近视是否发展，并及时采取控制措施。</p>
            <p>5.已发生近视的儿童青少年在使用低浓度阿托品或者佩戴角膜塑形镜（OK镜）减缓近视进展时，建议到正规医疗机构遵医嘱进行治疗。</p>
          </div>
        </div>

      </div>
      </div>
    </div>
  </div>
</template>

<script>
import selectService from '@/globals/service/detection/select.js'
import analysisService from '@/globals/service/detection/analysis.js'
import qs from 'qs'
import api from '@/globals/request/api.js'
import organizationService from '@/globals/service/management/organization.js'
import HtmlToPdf from '@/utils/htmlToPdf'

export default {
  components: {
    // TitleText
  },
  data () {
    return {
      selectSchool: null,
      schoolList: [],
      activeName: 0,
      inResize: false,
      screeningListId: null,
      screeningOptions: [],
      screeningObj: {},
      schoolId: '',
      gradeId: '',
      classId: '',
      schooldis: false,
      gradedis: false,
      classdis: false,
      school: '',
      nickName: '',
      newGradeId: null,
      schoolClassId: null,
      schoolOptions: [],
      gradeOptions: [],
      classOptions: [],
      startPageScreening: 1,
      pageSizeScreening: 100,
      startPageSchool: 1,
      pageSizeSchool: 100,
      tableData: {},
      gradeMapList: [],
      gradeClass: [],
      leftLevelArray: [],
      rightLevelArray: [],
      visionLevelArray: [],
      detailSHow: false,
      pdfStyle: ''
    }
  },
  created () {
  },
  mounted () {
    this.getScreeningSelect()
    // this.getListData()
    this.getSchoolList()
  },
  methods: {
    getScreeningSelectS () {
      this.schoolId = null
      this.schoolOptions = []
      this.screeningListId = null
      this.getScreeningSelect()
    },
    getSchoolList () {
      let data
      selectService.school({ startPage: 1, pageSize: 1000 }).then(res => {
        // const schoolId = storage.get('schoolId')
        // if (schoolId) {
        //   data = res.data.schoolList.filter(item => item.id === schoolId)
        // } else {
        //   data = res.data.schoolList
        // }
        data = res.data.schoolList
        data = data.map(item => {
          return {
            value: item.id,
            label: item.schoolName
          }
        })
        this.schoolList = data
        if (this.schoolList[0]) {
          this.selectSchool = this.schoolList[0].value
          // this.getListData()
        }
      })
    },
    getSchool () {
      if (!this.screeningListId) return
      this.schoolOptions = []
      this.gradeOptions = []
      this.classOptions = []
      organizationService.getSchoolAndUsers({ id: this.screeningListId }).then(res => {
        const data = []
        res.data.screeningVoList.forEach(item => {
          if (!data.some(items => items.schoolId === item.schoolId)) {
            data.push({
              schoolId: item.schoolId,
              schoolName: item.schoolName
            })
          }
        })
        this.schoolOptions = data
        if (this.schoolOptions[0]) {
          this.schoolId = this.schoolOptions[0].schoolId
          this.getListData()
          // this.getGradeSelect()
        }
      })
    },
    myChartDispose () {
      if (this.myChart1) this.myChart1.dispose()
      if (this.myChart2) this.myChart2.dispose()
      if (this.myChart3) this.myChart3.dispose()
      if (this.myChart4) this.myChart4.dispose()
      if (this.myChart5) this.myChart5.dispose()
      if (this.myChart6) this.myChart6.dispose()
      if (this.myChart7) this.myChart7.dispose()
    },
    handleClick () {
      this.screeningObj = null
      this.screeningListId = null
      this.schoolId = null
      this.schoolOptions = []
      this.myChartDispose()
      this.getScreeningSelect()
    },
    getScreeningSelect () {
      const data = { type: this.activeName, startPage: this.startPageScreening, pageSize: this.pageSizeScreening }
      if (this.selectSchool) data.schoolId = this.selectSchool
      selectService.screening(data).then(res => {
        const data = []
        res.data.list.forEach(item => {
          const zhi = data.some(items => item.id === items.id)
          if (!zhi) {
            data.push(item)
          }
        })
        const screeningOptions = data || []
        if (screeningOptions.length > 0) {
          const row = screeningOptions[0]
          this.screeningObj = row
          this.screeningListId = row.id
          // if(row.schoolId){
          // this.schoolOptions = [{
          //   value: row.schoolId,
          //   label: row.schoolName
          // }]
          this.getSchool()

          // this.schooldis = true
          // this.school = row.schoolId;
          // this.schoolId = row.schoolId
          //    this.getGradeSelect(row)
          //  }
          //  if(row.gradeId){
          //    this.gradeId = row.gradeId;
          //    this.newGradeId = row.gradeId;
          //    this.gradedis = true
          //  }
          //  if(row.classId){
          //    this.classId = row.classId;
          //    this.schoolClassId = row.classId;
          //    this.classdis = true
          //  }
        } else {
          // this.inResize = true
          // this.$alert('没有可选的筛查计划，请添加', '提示', {
          //   type: 'info',
          //   center: true,
          //   confirmButtonText: '确定',
          //   callback: action => {
          //     this.$router.push({ path: '/screeningPlanAdd' })
          //   }
          // })
        }
        this.screeningOptions = screeningOptions
      })
    },
    // searchScreening (row) {
    //   screeningServicr.search({ name: row }).then(res => {
    //     this.screeningOptions = res.data || []
    //   })
    // },
    changeScreening (index) {
      this.schoolId = ''
      //  this.gradeId = null
      //  this.classId = null
      //  this.schooldis = false
      //  this.gradedis = false
      //  this.classdis = false
      //  this.school = ''
      //  this.newGradeId = null
      //  this.schoolClassId = null
      this.myChartDispose()
      const row = this.screeningOptions[index] || null
      this.screeningObj = row
      //  this.screeningListId = row.id
      //  if(row.schoolId){
      // this.schoolOptions = [{
      //   value: row.schoolId,
      //   label: row.schoolName
      // }]
      //  this.schooldis = true
      //  this.school = row.schoolId;
      // this.schoolId = row.schoolId
      //    this.getGradeSelect(row)
      //  }
      //  if(row.gradeId){
      //    this.gradeId = row.gradeId;
      //    this.newGradeId = row.gradeId;
      //    this.gradedis = true
      //  }
      //  if(row.classId){
      //    this.classId = row.classId;
      //    this.schoolClassId = row.classId;
      //    this.classdis = true
      //  }
      //  this.searchScreening('')
      // const loading = this.$loading({
      //   lock: true,
      //   text: 'Loading',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // })
      // this.getListData()
      this.getSchool()
    },
    getGradeSelect (row) {
      if (!this.schoolId) {
        return false
      }
      this.gradeOptions = []
      this.classOptions = []
      if (row && row.gradeId) {
        this.gradeId = row.gradeId
        this.gradedis = true
      } else {
        this.gradeId = null
        this.classId = null
      }
      selectService.grade({ schoolId: this.schoolId + '' }).then(res => {
        const gradeList = res.data
        const gradeOptions = []
        gradeList.forEach(item => {
          gradeOptions.push({
            value: item.id,
            label: item.gradeName
          })
        })
        this.gradeOptions = gradeOptions
        this.getClassSelect(row)
      })
    },
    getClassSelect (row) {
      if (!this.gradeId) {
        return false
      }
      this.classOptions = []
      if (row && row.classId) {
        this.classId = row.classId
        this.classdis = true
      } else {
        this.classId = null
      }
      selectService.class({ gradeId: this.gradeId + '' }).then(res => {
        const classList = res.data
        const classOptions = []
        classList.forEach(item => {
          classOptions.push({
            value: item.id,
            label: item.className
          })
        })
        this.classOptions = classOptions
      })
    },
    getListData () {
      const that = this
      analysisService.wordReport({ schoolId: this.schoolId, id: that.screeningListId }).then(res => {
        that.tableData = res.data.data
        that.gradeMapList = res.data.gradeMapList
        that.gradeClass = res.data.gradeClass
        that.leftLevelArray = res.data.leftLevelArray
        that.rightLevelArray = res.data.rightLevelArray
        that.visionLevelArray = res.data.visionLevelArray

        that.$nextTick(() => {
          that.drawLine1()
          that.drawLine2()
          that.drawLine3()
          that.drawLine4()
          that.drawLine5()
          setTimeout(() => {
            that.gradeClass.forEach((item, index) => {
              that.gradeChart(item, index)
              that.gradeChartFb(item, index)
            })
            // loading.close()
          }, 500)
        })
      })
    },
    drawLine1 () {
      // 基于准备好的dom，初始化echarts实例
      // var domBugChart = this.$refs.myChart1;
      // var bugChartContainer = function () {
      //   if (domBugChart) {
      //     //domBugChart.style.width = 66 + "vw";
      //     // domBugChart.style.height = 70 + "vh";
      //   }
      // }
      // bugChartContainer();
      this.myChart1 = this.$echarts.init(document.getElementById('myChart1'))
      this.myChart1.setOption({
        title: {
          left: 'center',
          text: '全校双眼视力不良率统计',
          top: 20
        },
        legend: {
          top: 55,
          data: ['双眼不良率', '双眼优良率'],
          textStyle: {
            fontSize: 16
          },
          itemWidth: 14,
          itemHeight: 14
        },
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: '50%',
            center: ['50%', '60%'],
            color: ['#FFC000', '#F1EF0D'],
            label: {
              normal: {
                position: 'inner',
                formatter: '{d}%',
                color: '#fff',
                fontSize: '10px'
              }
            },
            data: [
              { value: this.tableData.badVisionNum, name: '双眼不良率' },
              { value: this.tableData.goodVisionNum, name: '双眼优良率' }
            ]

          }
        ]

      })
    },
    drawLine2 () {
      // 基于准备好的dom，初始化echarts实例
      this.myChart2 = this.$echarts.init(document.getElementById('myChart2'))

      this.myChart2.setOption({
        title: {
          left: 'center',
          text: '双眼分类视力不良率',
          top: 20
        },
        legend: {
          top: 55,
          data: ['需矫严重不良', '需矫视力不良', '轻度视力不良'],
          textStyle: {
            fontSize: 16
          },
          itemWidth: 14,
          itemHeight: 14
        },
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: '50%',
            center: ['50%', '60%'],
            color: ['#FF0000', '#FFC000', '#F1EF0D'],
            label: {
              normal: {
                position: 'inner',
                formatter: '{d}%',
                color: '#fff',
                fontSize: '10px'
              }
            },
            data: [
              { value: this.tableData.heavyVisionNum, name: '需矫严重不良' },
              { value: this.tableData.middleVisionNum, name: '需矫视力不良' },
              { value: this.tableData.lightVisionNum, name: '轻度视力不良' }
            ]
          }
        ]

      })
    },
    drawLine3 () {
      this.myChart3 = this.$echarts.init(document.getElementById('myChart3'))
      this.myChart3.setOption({
        title: {
          left: 'center',
          text: '视力左右眼分布图',
          top: 10
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: { // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        grid: {
          top: '15%',
          left: '10%',
          right: '5%',
          bottom: '25%'
          // containLabel: true
        },
        legend: {
          data: ['左眼', '右眼'],
          bottom: 0
        },
        color: ['#49a056', '#0b40ff'],
        xAxis: [
          {
            type: 'category',
            data: this.visionLevelArray,
            axisTick: {
              show: false // 隐藏x轴的分割点
            }
          }
        ],
        yAxis: {
          type: 'value',
          min: 0,
          axisLine: { show: true },
          axisLabel: {
            formatter: '{value} ',
            show: true,
            interval: 'auto'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(000, 000,000, .6)',
              width: 1
            }
          },
          boundaryGap: ['20%', '20%']
        },
        series: [
          {
            name: '左眼',
            type: 'bar',
            data: this.leftLevelArray
          },
          {
            name: '右眼',
            type: 'bar',
            data: this.rightLevelArray
          }
        ]
      })
    },

    drawLine4 () {
      // 基于准备好的dom，初始化echarts实例
      this.myChart4 = this.$echarts.init(document.getElementById('schoolReportMyChart4'))
      this.myChart4.setOption({
        title: {
          left: 'center',
          text: '全校双眼屈光不正占比统计',
          top: 20
        },
        legend: {
          top: 55,
          data: ['屈光异常率', '屈光正常率'],
          textStyle: {
            fontSize: 16
          },
          itemWidth: 14,
          itemHeight: 14
        },
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: '50%',
            center: ['50%', '60%'],
            color: ['#FFC000', '#F1EF0D'],
            label: {
              normal: {
                position: 'inner',
                formatter: '{d}%',
                color: '#fff',
                fontSize: '10px'
              }
            },
            data: [
              { value: this.tableData.badAmetropiaNum, name: '屈光异常率' },
              { value: this.tableData.goodAmetropiaNum, name: '屈光正常率' }
            ]

          }
        ]

      })
    },
    drawLine5 () {
      // 基于准备好的dom，初始化echarts实例
      this.myChart5 = this.$echarts.init(document.getElementById('myChart5'))
      this.myChart5.setOption({
        title: {
          left: 'center',
          text: '全校双眼分类屈光不正占比',
          top: 20
        },
        legend: {
          top: 55,
          data: ['重度屈光不正', '中度屈光不正', '轻度屈光不正'],
          textStyle: {
            fontSize: 16
          },
          itemWidth: 14,
          itemHeight: 14
        },
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: '50%',
            center: ['50%', '60%'],
            color: ['#FF0000', '#FFC000', '#F1EF0D'],
            label: {
              normal: {
                position: 'inner',
                formatter: '{d}%',
                color: '#fff',
                fontSize: '10px'
              }
            },
            data: [
              { value: this.tableData.heavyAmetropiaNum, name: '重度屈光不正' },
              { value: this.tableData.middleAmetropiaNum, name: '中度屈光不正' },
              { value: this.tableData.lightAmetropiaNum, name: '轻度屈光不正' }
            ]
          }
        ]
      })
    },

    gradeChart (item, index) {
      // 基于准备好的dom，初始化echarts实例
      this.myChart6 = this.$echarts.init(document.getElementById('gradeChartFb' + index))
      this.myChart6.setOption({
        title: {
          left: 'center',
          text: '全年级双眼屈光不良率',
          top: 20
        },
        legend: {
          top: 55,
          data: ['双眼不良率', '双眼优良率'],
          textStyle: {
            fontSize: 16
          },
          itemWidth: 14,
          itemHeight: 14
        },
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: '50%',
            center: ['50%', '60%'],
            color: ['#FFC000', '#F1EF0D'],
            label: {
              normal: {
                position: 'inner',
                formatter: '{d}%',
                color: '#fff',
                fontSize: '10px'
              }
            },
            data: [
              { value: item.badVisionNum, name: '双眼不良率' },
              { value: item.goodVisionNum, name: '双眼优良率' }
            ]
          }
        ]
      })
    },
    gradeChartFb (item, index) {
      // 基于准备好的dom，初始化echarts实例
      this.myChart7 = this.$echarts.init(document.getElementById('gradeChart' + index))
      this.myChart7.setOption({
        title: {
          left: 'center',
          text: '双眼分类屈光不良率',
          top: 20
        },
        legend: {
          top: 55,
          data: ['需矫严重不良', '需矫视力不良', '轻度视力不良'],
          textStyle: {
            fontSize: 16
          },
          itemWidth: 14,
          itemHeight: 14
        },
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: '50%',
            center: ['50%', '60%'],
            color: ['#FF0000', '#FFC000', '#F1EF0D'],
            label: {
              normal: {
                position: 'inner',
                formatter: '{d}%',
                color: '#fff',
                fontSize: '10px'
              }
            },
            data: [
              { value: item.heavyVisionNum, name: '需矫严重不良' },
              { value: item.middleVisionNum, name: '需矫视力不良' },
              { value: item.lightVisionNum, name: '轻度视力不良' }
            ]
          }
        ]
      })
    },
    goWord () {
      const jsonObj = {
        id: this.screeningListId,
        schoolId: this.schoolId,
        uid: window.sessionStorage.getItem('uid'),
        loginKey: window.sessionStorage.getItem('loginKey')
      }
      window.location.href = api.studentsWord + '?' + qs.stringify(jsonObj)
    },
    // gopPdf(){
    //   var prints = document.getElementById('htmlPdf');
    //   prints.title = "打印的标题";
    //     // printJS({
    //     //   printable: 'htmlPdf',
    //     //   type: 'html',
    //     //   targetStyles: ['*'],
    //     // })
    // },

    gopPdf () {
      const vm = this
      this.detailSHow = true
      const A4_WIDTH = 592.28
      const A4_HEIGHT = 841.89
      const screeningName = this.screeningObj.screeningName || '筛查报表'
      this.pdfStyle = 'width:800px;font-size:21px'// return
      vm.$nextTick(async () => {
        const loading = this.$loading({
          lock: true,
          text: '文件生成中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })

        // dom的id。
        const target = document.getElementById('htmlPdf')

        // 根据A4张的宽高比例计算每页的高度
        const pageHeight = target.scrollWidth / A4_WIDTH * A4_HEIGHT
        // console.log('每页高度', pageHeight)

        // 获取分割dom，此处为class类名为item的dom
        const lableListID = document.getElementsByClassName('page-item')
        // 进行分割操作，当dom内容已超出a4的高度，则将该dom前插入一个空dom，把他挤下去，分割
        console.log('len', lableListID.length)
        for (let i = 0; i < lableListID.length; i++) {
          // 当前元素底部距离顶端的距离 / 每页的高度 = 第几页
          const multiple = Math.ceil((lableListID[i].offsetTop + lableListID[i].offsetHeight) / pageHeight)
          // console.log('multiple', multiple)
          if (this.isSplit(lableListID, i, multiple * pageHeight)) {
            const divParent = lableListID[i].parentNode // 获取该div的父节点
            const newNode = document.createElement('div')
            newNode.className = 'emptyDiv'
            newNode.style.background = '#ffffff'
            const _H = multiple * pageHeight - (lableListID[i].offsetTop + lableListID[i].offsetHeight)
            const ph = 50
            // if (i === lableListID.length - 2 || i === lableListID.length - 1) {
            //   ph = 80
            // }
            newNode.style.height = _H - ph + 'px'
            newNode.style.width = '100%'
            const next = lableListID[i].nextSibling // 获取div的下一个兄弟节点
            // 判断兄弟节点是否存在
            if (next) {
              // 存在则将新节点插入到div的下一个兄弟节点之前，即div之后
              divParent.insertBefore(newNode, next)
            } else {
              // 不存在则直接添加到最后,appendChild默认添加到divParent的最后
              divParent.appendChild(newNode)
            }
          }
        }

        // 传入title和dom标签，此处是 #htmlPdf
        // 异步函数，导出成功后处理交互
        await HtmlToPdf.downloadPDF(target, screeningName + '-学校筛查计划报表')
        // 自定义等待动画关闭
        loading.close()
        this.detailSHow = false
        this.inResize = true
        this.pdfStyle = ''
        this.$message({
          type: 'success',
          message: '导出成功'
        })
        setTimeout(() => {
          this.getListData()
          this.inResize = false
        }, 200)
      })
    },
    isSplit (nodes, index, pageHeight) {
      // 计算当前这块dom是否跨越了a4大小，以此分割

      if (nodes[index].offsetTop + nodes[index].offsetHeight + 2 < pageHeight &&
        nodes[index + 1] && nodes[index + 1].offsetTop + nodes[index + 1].offsetHeight + 2 > pageHeight - 50) {
        return true
      }
      return false
    }
  }
}
</script>
<style lang="scss" scoped>
.header-container{
  width: 100%;
  height: 43px;
  border-bottom: 1px solid #f5f5f5;
  .header-section{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .route-name{
      line-height: 43px;
      font-size: 12px;
      color: #333;
    }
    .button-section{
      display: flex;
      align-items: center;
    }
  }
}
.page{
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 0 25px;
  background: #fff;
  min-width: 900px;
  background-color: #fff;
  min-height: 100%;
}
.warp{
  // padding: 0 26px;
  background: #fff;
  border-radius:8px;
  .header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    border-bottom:  1px solid #CCCCCC;
    font-size: 15px;
    font-weight: 500;
    color: #51B25F;
    span{
      border-left: 2px solid;
      padding: 0 8px;
      box-sizing: border-box;
    }
    .btn{
      width: 100px;
      height: 33px;
      background: #4FB25D;
      font-size: 13px;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
}
.red{
  color: #ff0000;
}
.tips{
  width: 100%;
  height: 33px;
  background: rgba(245, 98, 0, 0.05);
  border-radius: 4px;
  text-align: left;
  text-indent: 8px;
  font-size: 10px;
  font-weight: 400;
  color: #999999;
  line-height: 33px;
  border: 1px solid #FA6401;
  margin: 25px 0;
}
.select{
  text-align: left;
}
.select .el-input,.el-button,.el-select{
  margin-top: 5px;
  margin-bottom: 5px;
}
.select select{
  width: 208px;
  height: 33px;
  border-radius: 4px;
  border: 1px solid #CCCCCC;
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  outline: none;
  margin-right:8px;
}
.select select:last-child{
  width: 250px;
}
.report{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
  text-align: center;
  margin-bottom: 35px;
  border: 1px solid #CCCCCC;
  padding: 0 50px;
  color:#222222;
  line-height: 1.5;
}

.page-content{
  font-size: 24px;
}
.report header{
  padding-top: 20px;
}
.report header h1{
  font-size: 1.8em;
  font-weight: bold;
}
.report header h2{
  font-size: 1.6em;
}
.report .center{
  padding-top: 1em;
}
.report .header-title{
  font-size: 1.4em;
  padding: 0em 0 1em;
  font-weight: 700;
}

.report .center .time{
  text-align: right;
  font-size: 0.8em;
  padding: 0.4em 2em;
}
.report  .center .line{
  border: 1px solid #f7f904;
  width: 100%;
  margin: 0 auto;
}

.report .all h3{
  font-size: 1.2em;
  margin: 2em 0;
  font-weight: 700;
}
.report .center-tj .list{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.report .center-tj .list .item{
  min-width: 21%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.2em;
}
.report .center-tj .list  .item span{
  font-size: 0.8em;
}
.report .center-tj .list .item .classData{
  font-size: 1.4em;
  color: #ffa319;
}

.report .tips1{
  padding: 2em 0.5em 0 1em;
}
.report .tips1 div{
  font-size: 0.62em;
  text-align: left;
  line-height: 2;
}
.report .tips1 .red{
  line-height: 3;
}
.title{
  font-size: 1.2em;
  padding: 1.8em 0 1em;
}
.report .basic .title{
  font-weight: 700;
  padding: 2em 0 1.2em 0;
}
.report .basic .summary{
  text-align: left;
  display: flex;
  padding: 1em;
  font-size: 0.9em;
}
.report .basic .summary div{
  font-weight: 700;
}
.report .basic .summary .shu{
  height: 1em;
  width: 1px;
  background: #000;
  margin: 0.2em 0 0 0;
}
.report .basic .summary .shu2{
  height: 0.8em;
  width: 2px;
  background: #000;
  margin: 0.2em 0.3em 0 0.1em;
}
.report .basic h3{
  color: #666666;
  font-size: 0.75em;
  line-height: 2.4;
  text-indent: 2em;
  text-align: left;
}
.report .basic .box{
  border: 1px solid #1147af;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-around;
  line-height: 2;
  padding: 2em 0;
}
.report .basic .box span{
  font-weight: 700;
}
.report .basic .result{
  display: flex;
}
.report .basic .result .title{
  font-weight: 500;
  font-size: 1em;
  padding: 2em 0 0.8em 0;
}
.report .basic .list{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
  box-sizing: border-box;
}
.report .basic .list .item{
  min-width: 21%;
  padding: 0.4em;
  display: flex;
  font-size: 0.9em;
  flex-direction: column;
}
.report .basic .list .item .classData{
  color: #ffa319;
}

.report .basic .result .list{
}
.report .basic .result .list .item .classData{

}
.report .basic .result .left,.right{
  display: flex;
  flex-direction: column;
  flex: 1;
}
.report .basic .result .line2{
  margin: 2em 0.8em 0;
  padding-bottom: 1em;
  background: #f7f904;
  width: 2px;
}
.tableBox{
  text-align: center;
  padding: 1em 0;
}
.tableBox .title{
  font-size: 1.2em;
  font-weight: 700;
}
.report table {
  width: 95%;
  margin: auto;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  border-collapse:collapse;
}
.chartBox{
  border: 1px solid #CCCCCC;
}
.chartBox:last-child{
  margin-top: 0.6em;
}
#myChart3{
  margin-bottom: 2em;
}
.report table td {
  font-size: 0.7em;
  padding: 0.6em 0.8em;
  border-left: 1px solid #000;
  border-top: 1px solid #000;
}
.uneven{
  text-align: left;
  padding: 2em 0 4em;
}
.uneven .title{
  font-size: 1.2em;
  font-weight: bold;
  color: #000;
  text-align: center;
}
.uneven .summary{
  font-size: 0.8em;
  line-height: 2;
  color: #ff0000;
}
.uneven .details{
  font-size: 0.8em;
  line-height: 2;
  padding: 1em 0;
}
.uneven .unevenTips{
  font-size: 0.8em;
  line-height: 2;
  color: #ff0000;
}
.review{
  min-height: 15em;
  margin-bottom: 2em;
}
.fcsm{
  padding-top: 2em;
}
.fcsm .title{
  font-size: 1.2em;
  font-weight: 700;
  padding: 1em 0;
}
.fcsm .t2{
  text-align: left;
  margin: 10px 0;
}
.fcsm .text{
  line-height: 1.5;
  text-indent: 2em;
  text-align: left;
}
.mainTitle{
  padding: 18em 4em;
}
.classTitle{
  font-size: 1.2em;
  padding: 2.8em 0 1em;
  font-weight: 700;
}
.Classes .title{
  font-size: 1.1em;
  font-weight: 700;
}
//.Classes table{
//  margin-bottom: 2em;
//}
.title:nth-child(2){
  margin: 0.5em 0;
}

.title2{
  line-height: 0px;
}

.footer-section{
  padding-top: 6em;
  text-align: left;
  font-weight: bold;
}
.footer-section .suggestion{
  font-size: 1em;
  padding: 0.6em;
  font-weight: bold;
}
.footer-section .suggestion-title{
  font-size: 0.8em;
  font-weight: bold;
}
.classData{
  //background: #FEFF00;
}
.red{
    color: #f50e0e;
  }

ul.desc{
  padding-left: 20px;
}
</style>
